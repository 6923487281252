import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button, InputGroup, Breadcrumb, Table } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faAngleDown, faEye } from "@fortawesome/free-solid-svg-icons";
import Parse from '../vendor/Parse'
import { useLocation } from '@reach/router';
import NumberTable from '../components/NumberTable'
import { Modal } from '@themesberg/react-bootstrap';
import useAuth from "../hooks/userAuth";

const { useState, useEffect } = React;

const Profile1 = "images/2021-01-30.jpg";
const IndexPage = () => {

  const location = useLocation();
  const qs = new URLSearchParams(location.search)
  const { isLogin } = useAuth()
  const [items, setItems] = useState([])
  const [query, setQuery] = useState(qs.get('search') || "")
  const [checklist, setChecklist] = useState([])
  const [suggestion, setSuggestion] = useState([])
  const [displayInfo, setDisplayInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuggestionLoading, setSuggestionLoading] = useState(false)
  const [activeNumber, setActiveNumber] = useState(null)
  const searchNumbers = async (query) => {
    setIsLoading(true)
    const res = await Parse.Cloud.run('queryNumbers', {
      query
    })
    setItems(res.data)
    setIsLoading(false)
  }

  useEffect(() => {
    if (activeNumber) {
      setDisplayInfo(<>
        <h2 className="text-center">{activeNumber?.num}</h2>
        <h2 className="text-center">Provider: {activeNumber?.sourceId}</h2>
        <br />
        Simply sign up 4 month plan under us from circles.life $30/monthly and we will help you secure this number and deliver it to you.
        Powerful plan which you can travel with peace of mind for romaing.
        <ul>
          <li>100GB Borderless (5G)</li>
          <li><b>Data shared across 9 Countries</b></li>
          <li>Singapore, Malaysia, Indonesia, Thailand, Taiwan, Hong Kong, Macau, Sri Lanka & Bangladesh</li>
          <li><b>* Unlimited Local Talktime (Incoming & Outgoing)</b></li>
          <li>* 25 SMS</li>
        </ul>

        <h4>And we also grant you access to our db which tell which tell you the provider of the number</h4>
      </>)
    }
  }, [activeNumber])

  const notWhatsppFlow = ['admin', 'user'].includes(isLogin?.role)
  const getMySuggestion = async () => {
    setSuggestionLoading(true)
    const res = await Parse.Cloud.run('getMySuggestedNumber')
    setChecklist(res.checklist)
    setSuggestion(res.suggestion)
    setSuggestionLoading(false)
  }

  React.useEffect(() => {

    searchNumbers(query)
    getMySuggestion()

  }, [])

  const handleSearchButton = async () => {

    await searchNumbers(query)

  }

  const handleItem = async (item) => {
    const res = await Parse.Cloud.run('querySpecificNumber', {
      _id: item._id
    })

    setActiveNumber(res)



  }

  const handleClose = () => {
    setActiveNumber(null)
  }

  const cols = [{ label: 'Number', key: 'num' }, { label: 'Last Seen', key: 'date' }, {
    label: 'See', onRender: (item) => {
      return <Button onClick={() => handleItem(item)}>
        <FontAwesomeIcon icon={faEye} />
      </Button>
    }
  }]



  return <Layout afterSearch={<></>} >
    <h3 className="text-center">SG Mobile Number Finding</h3>
    <h2 className="text-center">
      <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=^${activeNumber?.num}`}>whatsapp</a> us to access our number list
    </h2>
    <h3>Monitoring List - <span style={{ color: 'red' }}>We will Give You First Hand Information on your Monitoring List.</span></h3>
    <h4> </h4>
    <div>
      <h4>{checklist.join(', ')}</h4>
      <NumberTable
        cols={cols}
        isLoading={isSuggestionLoading}
        items={suggestion}
        onItem={(item) => { handleItem(item) }}
      />

    </div>

    <h3>Free Search For Other Number</h3>
    <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 flex-column">
      <Form className="">
        <Form.Group id="topbarSearch">
          <InputGroup className="input-group-merge search-bar">
            <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            <Form.Control type="text" name="search" placeholder="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
            <Button onClick={handleSearchButton} variant="primary" size="sm" className="me-2">
              Find
            </Button>
          </InputGroup>
        </Form.Group>
      </Form>
      <NumberTable cols={cols} isLoading={isLoading} items={items} onItem={item => handleItem && handleItem(item)} />


    </div>


    <Modal as={Modal.Dialog} centered show={activeNumber} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Information</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {displayInfo}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
          Close
        </Button>
        <Button variant='#25D366' style={{ backgroundColor: '#25D366', textDecoration: 'none' }} as={Link} to={`https://api.whatsapp.com/send?phone=6587413159&text=Enquiry on ${activeNumber?.num}`}>
          Whatapps Us
        </Button>

      </Modal.Footer>
    </Modal>

  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
